<template>
  <!-- eslint-disable -->

  <div class="flex-lg-row-fluid ms-lg-10">
    <div class="card mb-5 mb-xl-10">
      <div class="card-header card-header-stretch">
        <h3 class="card-title fw-bolder text-gray-800 fs-2">Wnioski o dostęp</h3><div class="card-toolbar m-0">
				<ul class="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 fw-bolder" role="tablist">
					<li @click="type='external'" class="nav-item" role="presentation">
						<a :class="type=='external' ? 'active' : ''" class="nav-link justify-content-center text-active-gray-800" role="tab">Klienci zewnętrzni <small v-if="externalRequestsCount !== false" class="mx-2 badge badge-square" :class="externalRequestsCount > 0 ? 'badge-primary' : 'badge-secondary'">{{ externalRequestsCount }}</small></a>
					</li>
					<li @click="type='internal'" class="nav-item" role="presentation">
						<a :class="type=='internal' ? 'active' : ''" class="nav-link justify-content-center text-active-gray-800" role="tab">Organy AAB <small v-if="internalRequestsCount !== false" class="mx-2 badge badge-square" :class="internalRequestsCount > 0 ? 'badge-primary' : 'badge-secondary'">{{ internalRequestsCount }}</small></a>
					</li>
				</ul>
			</div>
      </div>

      <div v-if="type=='external'" class="card-body pt-3">
				<div v-for="request in externalRequests" class="item-border-hover px-3 py-2 ms-n4 mb-3 row">
					<div class="align-items-center col-md-7">
						<div class="ps-1 mb-1">
							<router-link :to="'/admin/requests/'+request.uuid" class="fs-5 text-gray-800 text-hover-primary fw-boldest">{{ request.name }}</router-link>
  					  <div class="fs-7">{{ request.role_name }}</div>
						</div>
					</div>
          <div class="col-md-5">
            <div class="fw-bolder text-end fs-5 mt-4">{{ request.email }}</div>
          </div>
				</div>
			</div>
      <div v-else-if="type=='internal'" class="card-body pt-1">
				<div v-for="request in internalRequests" class="item-border-hover px-3 py-2 ms-n4 mb-3 row">
					<div class="align-items-center col-md-7">
						<div class="ps-1 mb-1">
							<router-link :to="'/admin/requests/'+request.uuid" class="fs-5 text-gray-800 text-hover-primary fw-boldest">{{ request.name }}</router-link>
  					  <div class="fs-7">{{ request.role_name }}</div>
						</div>
					</div>
          <div class="col-md-5">
            <div class="fw-bolder text-end fs-5 mt-4">{{ request.email }}</div>
          </div>
				</div>
			</div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminRequests',
  inject: ['$profile'],
  data() {
    return {
      timer: false,
      type: 'external',
      internalRequests: [],
      externalRequests: [],
      internalRequestsCount: false,
      externalRequestsCount: false
    }
  },
  mounted() {
    this.reload();
    this.timer = setInterval(this.reload, 60000);
  },
  beforeUnmount() {
  },
  methods: {
    reload: function() {
      var self = this;
      this.$root.$refs.api.get('requests?type=internal').then(function (response) {
        if (typeof response.data !== 'undefined' && Array.isArray(response.data))
        {
          self.internalRequests = response.data;
          self.internalRequestsCount = response.data.length;
        }
      })
      .catch(function (error) {
        console.log(error);
        self.internalRequests = [];
        self.internalRequestsCount = false;
      });
      this.$root.$refs.api.get('requests?type=external').then(function (response) {
        if (typeof response.data !== 'undefined' && Array.isArray(response.data))
        {
          self.externalRequests = response.data;
          self.externalRequestsCount = response.data.length;

        }
      })
      .catch(function (error) {
        console.log(error);
        self.externalRequests = [];
        self.externalRequestsCount = false;
      });
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
  },
  computed: {
    profile() {
      return this.$profile();
    }
  },
  components: {
  }
};
</script>

<style>
ul.roles {
  list-style-type: none;
  padding: 0;
}
ul.roles li {
  padding: 0;
  padding-bottom: 8px;
}
</style>
